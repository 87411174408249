import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return {selector: to.hash}
    if (savedPosition) return savedPosition

    return {x: 0, y: 0}
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
    },
    {
      path: '/huisbezoeken',
      name: 'huisbezoeken',
      component: () => import(/* webpackChunkName: "huisbezoeken" */ '@/views/Huisbezoeken.vue')
    },
    {
      path: '/wachtdiensten',
      name: 'wachtdiensten',
      component: () => import(/* webpackChunkName: "wachtdiensten" */ '@/views/Wachtdiensten.vue')
    },
    {
      path: '/noodnummers',
      name: 'noodnummers',
      component: () => import(/* webpackChunkName: "noodnummers" */ '@/views/Noodnummers.vue')
    },
    {
      path: '/gmd',
      name: 'gmd',
      component: () => import(/* webpackChunkName: "gmd" */ '@/views/Gmd.vue')
    },
    {
      path: '/griepvaccinatie',
      name: 'griepvaccinatie',
      component: () => import(/* webpackChunkName: "griepvaccinatie" */ '@/views/GriepVaccinatie.vue')
    },
    {
      path: '/links',
      name: 'links',
      component: () => import(/* webpackChunkName: "links" */ '@/views/Links.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import(/* webpackChunkName: "contact" */ '@/views/Contact.vue')
    },

    {path: '*', component: () => import(/* webpackChunkName: "not-found" */ '@/views/404.vue')}
  ],
})

export default router
