<template>
  <router-view :key="$route.path"/>
</template>

<script>
  export default {

    metaInfo () {
      return {
        title: 'Huisartsenpraktijk Robyns',
        titleTemplate: `%s - Huisartsenpraktijk Robyns`
      }
    }
  }
</script>

<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
</style>
