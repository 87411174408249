import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'
import nl from '@/locales/nl'
import fr from '@/locales/fr'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'nl',
  messages: { nl, fr },
})

export async function setLocale (locale) {
  if (i18n.locale !== locale) {
    i18n.locale = locale
    store.dispatch('lang/setLocale', { locale })
    document.querySelector('html').setAttribute('lang', locale)
  }
}

export default i18n
